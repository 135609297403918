import "./style.css";

import OmCafeWhite from "../images/text/OmCafeWhite.png";

export default function BottomPanel() {
  return (
    <div className="bottom-panel">
      <img src={OmCafeWhite} alt="logo" />
      <div className="bottom-panel__text">
        Ljubav za sva čula, to je vaša priča od prve popijene šoljice Omcafe.
        Čulo mirisa i ukusa nadahnuto posebnim ritualom pripreme i ispijanja –
        zaslužno je za ples aroma kojem se uvijek rado vraćate. To je najčistija
        ljubavna priča – u kojoj ste upoznali svoju omiljenu vrstu kafe. Ljubav
        je put ka dugovječnoj sreći, a naše zadovoljstvo da OMCAFÉ sa vama
        svakodnevno dijeli trenutke radosti.
      </div>
      <div className="bottom-panel__image"></div>
    </div>
  );
}
