import React from 'react'

// style
import './style.css';

// components
import { Container, Row, Col } from 'react-bootstrap';

import Coffee from '../../assets/images/intro/Coffee'
import shadow from '../../assets/images/intro/shadow.jpg'
import instant from './images/instant-omcafe.jpg'

// products

import omcafe2in1 from './images/products/Omcafe 2 in 1.png'
import omcafe2in1soya from './images/products/Omcafe 2 in 1 soya.png'
import omcafe3in1soyaVannila from './images/products/Omcafe 3 in 1 soya vanilla.png'
import omcafe3in1soya from './images/products/Omcafe 3 in 1 soya.png'
import omcafe3in1 from './images/products/Omcafe 3 in 1.png'
import omcafe3in1smedjiSecer from './images/products/Omcafe 3in1 smedji secer.png'
import omcafeCapucino from './images/products/Omcafe capucino.png'

const Instant = () => {
    return (
        <>
            <div>
                <img className="intro-image-instant" src={instant} alt="Omcafé" />
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} className="text-center">
                        <p className="text-intro-instant">
                            Požuri polako – bila bi naša topla preporuka zvana OMCAFÉ instant. Kada se dnevni raspored zgusne, a pauza za predah postane neophodna, jedna kašičica ove kafe učiniće da vrijeme počne raditi za vas.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Coffee className="beans-intro-image-instant" />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <img className="shadow-intro-image-instant" src={shadow} alt="Omcafé" />
                    </Col>
                </Row>


                <Row>
                    <Col lg={12}>


                        <div className="card-instant bg-2in1-red">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">Omcafé 2 in 1</h2>
                                        <p className="text-card-instant">Jedna šolja prave kafe popravlja mnoge krive stvari. OMCAFÉ 2 u 1 u prevodu znači: dobro raspoloženje i nova doza energije u jednoj kesici.</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-2in1/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-instant">Dodaj u korpu</button>
                                        </a>
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe2in1} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>


                        <div className="card-instant bg-3in1-gold">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">Omcafé 3 In 1 smeđi šećer</h2>
                                        <p className="text-card-instant">Kad poželite vašem najdražem napitku dodati zrnce novine, tu je Omcafé 3 u 1 sa smeđim šećerom, koji kafi daje intenzivniji, karamelizovan ukus i tamniju boju. Male promjene nekad čine velika zadovoljstva.</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-3in1-smedji-secer/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-instant">Dodaj u korpu</button>
                                        </a>                                        
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe3in1smedjiSecer} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>


                        <div className="card-instant bg-3in1-gray">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">Omcafé 3 In 1</h2>
                                        <p className="text-card-instant">Klasika nikad ne izlazi iz mode. Zato savršenoj recepturi kafe, mlijeka i šećera ne trebaju nove riječi, samo uživanje u svakom novom ponavljanju.</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-3in1/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-instant">Dodaj u korpu</button>
                                        </a>
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe3in1} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>

                        <div className="card-instant bg-capucino">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">Cappuccino</h2>
                                        <p className="text-card-instant">Ljubavno pismo Italije ostatku svijeta nazvano je kapućino. Za samo nekoliko sekundi pripreme, OMCAFÉ Cappuccino pred vama otvara stranice ove nježne, pjenaste kofeinske priče. Svilenkasta aroma kafe prožeta mlijekom, prekrivena savršeno prozračnom pjenicom, sadržaj vaše šoljice pretvara u višeslojni dar za nepce.</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-cappuccino-classical/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-instant">Dodaj u korpu</button>
                                        </a>                                        
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafeCapucino} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>

                        <div className="card-instant bg-soya-2in1-blue">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">3 In 1 Soya Natural</h2>
                                        <p className="text-card-instant">Ispijanje kafe treba da uvijek bude dostupan užitak. Uz zrnce slasti, neka ništa ne stoji na putu ka vašim dnevnim zadovoljstvima.</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-3in1-soya-natural/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-instant">Dodaj u korpu</button>
                                        </a>                                        
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe3in1soya} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>


                        <div className="card-instant bg-soya-3in1-vanilla-yellow">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">3 In 1 Soya Vanilla </h2>
                                        <p className="text-card-instant">Ovaj aromatični biljni trio donosi posebnu kombinaciju ukusa: razigranu aromu vanilije udruženu sa blagim notama sojinog mlijeka, na osnovi od zaslađenih zrnaca fino mljevene kafe.</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-3in1-soya-vanila/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-instant">Dodaj u korpu</button>
                                        </a>                                        
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe3in1soyaVannila} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>

                        <div className="card-instant  bg-soya-3in1-green last-card-instant">
                            <Row className="column-reverse-card-instant">
                                <Col lg={6} className="col-align-left-card-instant">
                                    <div className="align-content-right-card-instant">
                                        <h2 className="title-card-instant">2 In 1 Soya Natural</h2>
                                        <p className="text-card-instant">Ukoliko zbog posebnog režima ishrane ili jednostavno ukusa u vašoj kafi preferirate biljno mlijeko umjesto kravljeg, dobrodošli ste u svijet OMCAFÉ instant kafe sa dodatkom sojinog mlijeka.</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-2in1-soya-natural-82-gratis/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-instant">Dodaj u korpu</button>
                                        </a>                            
                                    </div>
                                </Col>
                                <Col lg={6} className="col-align-right-card-instant">
                                    <img className="image-card-instant" src={omcafe2in1soya} alt="Omcafé" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Instant;