import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/index.css";

// screens
import Home from "./screens/Home";
import Espresso from "./screens/Espresso";
import Capsule from "./screens/Capsule";
import Instant from "./screens/Instant";
import Tradicionalna from "./screens/Tradicionalna";

// news
import NewsOrganskaKafa from "./screens/News/NewsOrganskaKafa";
import NewsAparat from "./screens/News/NewsAparat";
import AllNews from "./screens/AllNews";
import AboutUs from "./screens/AboutUs";
import Contact from "./screens/Contact";
import Layout from "./components/Layout/Layout";
import TarikFilipovicNoviBrendAmbasador from "./screens/News/TarikFilipovicNoviBrendAmbasador";

import ZenskePrice from "./screens/ZenskePrice";
import Marijana from "./screens/ZenskePrice/Marijana";
import Elena from "./screens/ZenskePrice/Elena";
import Isidora from "./screens/ZenskePrice/Isidora";
import Emina from "./screens/ZenskePrice/Emina";
import Iva from "./screens/ZenskePrice/Iva";
import Nastja from "./screens/ZenskePrice/Nastja";
import Emilija from "./screens/ZenskePrice/Emilija";
import Galina from "./screens/ZenskePrice/Galina";
import Ines from "./screens/ZenskePrice/Ines";
import Lejla from "./screens/ZenskePrice/Lejla";

import KafaKojaDise from "./screens/News/KafaKojaDise";
import TurskaKafa from "./screens/News/TurskaKafa";

// import NagradnaIgra from "./screens/NagradnaIgra/NagradnaIgra";
// import DobitniciNagradneIgre from "./screens/NagradnaIgra/DobitniciNagradneIgre";
import VR from "./screens/VR";
import TarikFilipovic6KljucnihFaktora from "./screens/News/TarikFilipovic6KljucnihFaktora";
import Katalog from "./screens/Katalog";

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <Layout>
          <Routes>
            <Route key="VR" path="/espresso-aparat-vr" exact element={<VR />} />
            <Route key="Home" path="/" exact element={<Home />} />
            <Route
              key="Espresso"
              path="/espresso-kafa"
              exact
              element={<Espresso />}
            />
            <Route
              key="Capsule"
              path="/espresso-capsule"
              exact
              element={<Capsule />}
            />
            <Route
              key="Instant"
              path="/instant-kafa"
              exact
              element={<Instant />}
            />
            <Route
              key="Tradicionalna"
              path="/tradicionalna-kafa"
              exact
              element={<Tradicionalna />}
            />
            <Route key="AllNews" path="/novosti" exact element={<AllNews />} />
            <Route
              key="NewsOrganskaKafa"
              path="/omcafe-organska-kafa"
              exact
              element={<NewsOrganskaKafa />}
            />
            <Route
              key="NewsAparat"
              path="/omcafe-espresso-capsule-aparat"
              exact
              element={<NewsAparat />}
            />
            <Route
              key="TarikFilipovicNoviBrendAmbasador"
              path="/tarik-filipovic-novi-brend-ambasador"
              exact
              element={<TarikFilipovicNoviBrendAmbasador />}
            />
            <Route
              key="TarikFilipovic6KljucnihFaktora"
              path="/tarik-filipovic-6-kljucnih-faktora"
              exact
              element={<TarikFilipovic6KljucnihFaktora />}
            />

            <Route key="AboutUs" path="/o-nama" exact element={<AboutUs />} />
            <Route key="Contact" path="/kontakt" exact element={<Contact />} />

            <Route
              key="ZenskePrice"
              path="/zenske-price"
              exact
              element={<ZenskePrice />}
            />
            <Route
              key="Marijana"
              path="/marijana"
              exact
              element={<Marijana />}
            />
            <Route key="Elena" path="/elena" exact element={<Elena />} />
            <Route key="Isidora" path="/isidora" exact element={<Isidora />} />
            <Route key="Emina" path="/emina" exact element={<Emina />} />
            <Route key="Iva" path="/iva" exact element={<Iva />} />
            <Route key="Nastja" path="/nastja" exact element={<Nastja />} />
            <Route key="Emilija" path="/emilija" exact element={<Emilija />} />
            <Route key="Galina" path="/galina" exact element={<Galina />} />
            <Route key="Ines" path="/ines" exact element={<Ines />} />
            <Route key="Lejla" path="/lejla" exact element={<Lejla />} />

            <Route
              key="KafaKojaDise"
              path="/kafa-koja-dise"
              exact
              element={<KafaKojaDise />}
            />
            <Route
              key="TurskaKafa"
              path="/kafa-koja-je-unistila-osmansko-carstvo"
              exact
              element={<TurskaKafa />}
            />
            <Route key="Katalog" path="/katalog" exact element={<Katalog />} />

            {/* <Route
              key="NagradnaIgra"
              path="/nagradna-igra"
              exact
              element={<NagradnaIgra />}
            />
            <Route
              key="NagradnaIgraDobitnici"
              path="/nagradna-igra/dobitnici"
              exact
              element={<DobitniciNagradneIgre />}
            /> */}
          </Routes>
        </Layout>
      </Router>
    </React.Fragment>
  );
};

export default App;
