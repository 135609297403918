import React from "react";

export const VR = () => {
  const iframeStyle = {
    width: "100%",
    height: "100%",
    zIndex: 2,
    position: "fixed",
    top: 0,
  };

  return (
    <iframe style={iframeStyle} title="VR" src="/ar-aparat/index.html"></iframe>
  );
};
