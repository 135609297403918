import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import katalog from "./Katalog.pdf";


import './style.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const MobileCatalog = () => {
  const [numPages, setNumPages] = useState(null);
  const [loadedPercentage, setLoadedPercentage] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const spinner = (
    <div class="spinner-wrapper">
        <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow={loadedPercentage}
            aria-valuemin="0" aria-valuemax="100" style={{ width: `${loadedPercentage}%` }}>
                <span class="sr-only">70% Complete</span>
            </div>
        </div>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
  )

  return (
    <div>
      <Document
      className="pdf-document"
        file={katalog}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadProgress={({loaded, total}) => {
            setLoadedPercentage(Math.floor(loaded/total) * 100);
        }}
        options={{
            cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
        }}
        loading={spinner}
        renderMode="svg"
        >
        {Array.from(new Array(numPages), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                loading={spinner}
                width={600}
              />
            ))}
      </Document>
    </div>
  );
}