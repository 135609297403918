import React, { useEffect } from 'react'
import './style.css'
import Navigation from '../Navigation';
import Footer from '../Footer';

const Layout = (props) => {
    useEffect(() => { window.scrollTo(0, 0) });
    const { children } = { ...props }
    return (
        <>
            <Navigation />
                <div className="layout">
                    {children}
                </div>
            <Footer />
        </>
    )
}

export default Layout