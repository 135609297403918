import React from 'react'

// components
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";


// style
import '../Home/style.css';
import './style.css';

// images
import OrganicCoffee from "../Home/images/Omcafe-organic-coffee.jpg";
import aparat from '../Home/images/Omcafe espresso capsule aparat.jpg'
import prodavnica from '../Home/images/Omcafe online prodavnica.jpg'
import tarik from '../Home/images/Omcafe Espresso Capsule - Tarik Filipovic.jpg'
import zenskePricePaket from '../Home/images/poklon-paket-omcafe-zenske-price.jpg'
import kafaKojaDise from '../Home/images/kafa-koja-dise.jpg'
import slika1 from '../Home/images/turska.jpg'

const AllNews = () => {
    return (
        <Container fluid>
            <Row className="column-reverse-card-home">
                <Col lg={6} className="p-0">
                    <img src={slika1} alt="Omcafe - Kafa koja dise" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Novosti</h4>
                        <h2 className="title-card-home">Kafa koja je uništila Osmansko carstvo</h2>
                        <p className="text-card-home">
                        Kafa ili “kahve” je od samog početka bila omiljini napitak vladajuće klase Osmanskog carstva, ali niko nije slutio da će ona ubrzati njegovu propast.
                        </p>
                        <Link to="/kafa-koja-je-unistila-osmansko-carstvo" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Nastavi čitanje
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="p-0">
                    <img src={kafaKojaDise} alt="Omcafe - Kafa koja dise" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Novosti</h4>
                        <h2 className="title-card-home">Kafa koja „diše“</h2>
                        <p className="text-card-home">
                            Ako ste veliki ljubitelj kafe, sigurno ste primjetili da se na vrećicama za kafu nalazi jednosmjerni  ventil za degasifikaciju koji omogućava zrnima kafe da „dišu“.
                        </p>
                        <Link to="/kafa-koja-dise" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Nastavi čitanje
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="column-reverse-card-home">
            <Col lg={6} className="p-0">
                    <img src={zenskePricePaket} alt="Poklon paket Ženske priče - Omcafe BIH" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Novosti</h4>
                        <h2 className="title-card-home">Poklon paket "Ženske priče"</h2>
                        <p className="text-card-home">
                            Obradujte ženu koju volite, zahvalite joj i recite nešto lijepo – poklonite joj paket „Ženskih priča“, sa 350 g Premium kafe i posebno dizajniranom šoljicom. Uskoro dostupno u svim većim prodajnim mjestima.
                        </p>
                        <Link to="/zenske-price" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Pročitaj priče
                            </button>
                        </Link>
                    </div>
                </Col>
              
            </Row>
            <Row>
                <Col lg={6} className="p-0">
                    <img src={tarik} alt="Omcafe Espresso Capsule Tarik Filipović" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Novosti</h4>
                        <h2 className="title-card-home">Tarik Filipović je novi ambasador brenda</h2>
                        <p className="text-card-home">
                            Glumac i televizijski voditelj Tarik Filipović u narednom periodu biće zaštitno lice OMCAFÉ Espresso
                            Capsula. Počevši od 19. oktobra, Tarik Filipović će biti predstavljen u novoj OMCAFÉ Espresso Capsule
                            marketinškoj kampanji koja nas poziva da „izaberemo svoj užitak“, putem televizijskog, štampanog i
                            digitalnog oglašavanja.
                                </p>
                        <Link to="/tarik-filipovic-novi-brend-ambasador" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Nastavi čitanje
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="column-reverse-card-home">
            <Col lg={6} className="p-0">
                    <img src={prodavnica} alt="Omcafe BIH" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Novo - OMCAFÉ online prodavnica</h4>
                        <h2 className="title-card-home">OMCAFÉ online prodavnica</h2>
                        <p className="text-card-home">
                            Dragi kafoljupci, od sada vaše omiljene OMCAFÉ proizvode možete pronaći na novootvorenoj web prodavnici na našem sajtu. Iz sigurnosti vašeg doma naručite željene količine i ubrzo ih očekujte na vašoj adresi uz plaćanje pouzećem. Prodavnici pristupite na našem sajtu: <u>shop.omcafe.net</u> i uživajte u kupovini.
                                </p>
                        <a href="https://shop.omcafe.net/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Posjeti prodavnicu
                                    </button>
                        </a>
                    </div>
                </Col>
                
            </Row>
            <Row>
                <Col lg={6} className="p-0">
                    <img src={aparat} alt="Omcafe BIH" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Novi proizvod</h4>
                        <h2 className="title-card-home">OMCAFÉ Espresso Capsule aparat</h2>
                        <p className="text-card-home">
                            Ako ste privrženi izvornoj aromi espresa i rado biste svoj ritual unijeli u kućni ili poslovni ambijent, za vas smo ponudu obogatili novim proizvodom, OMCAFÉ Espresso Capsule aparatom i kapsulama. Riječ je o alternativi ulaganju u uređaj profesionalne klase pristupačnoj svakom domaćinstvu koju odlikuju brzina, praktičnost i konzistentnost u procesu pripreme kafe. Zato neka za postojanost vašeg užitka od sada bude zadužen naš espreso aparat koji iz kapsula punjenih savršenim omjerom vrhunskih sastojaka u samo nekoliko sekundi proizvodi napitak opojnih aroma.
                                    </p>
                        <Link to="/omcafe-espresso-capsule-aparat" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Nastavi čitanje
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className="column-reverse-card-home">
            <Col lg={6} className="p-0">
                    <img src={OrganicCoffee} alt="Omcafe BIH" className="image-card-home" />
                </Col>
                <Col lg={6} className="p-0">
                    <div className="card-home">
                        <h4 className="subtitle-card-home">Novi proizvod - organska kafa</h4>
                        <h2 className="title-card-home">Kad priroda i tijelo kažu hvala</h2>
                        <p className="text-card-home">
                            Misliti na svoju ali i dobrobit okoline čak i u trenucima ličnog uživanja odavno je postao imperativ u ishrani. Namirnice koje svakodnevno unosimo, mnoge od njih proizvedene na veoma udaljenim krajevima planete, dugotrajnom konzumacijom utiču na naš organizam, zbog čega je važno posvetiti posebnu pažnju njihovom kvalitetu odnosno načinu proizvodnje. Osim toga, sam proces proizvodnje podrazumijeva ne samo nastanka proizvoda, već cio trgovinski lanac od proizvođača do potrošača, u kojem mnogi učesnici ne dobiju pravednu raspodjelu sredstava. To se posebno odnosi na siromašne zemlju sa često neuređenim radnim pravima pa čak i eksploatacijom dječijeg rada.
                                    </p>
                        <Link to="/omcafe-organska-kafa" className="reset-link-style">
                            <button type="button" className="button-card-home">
                                Nastavi čitanje
                                        </button>
                        </Link>
                    </div>
                </Col>
               
            </Row>
        </Container>
    )
}

export default AllNews;