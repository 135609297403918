import React, { useState } from "react";

// products
import OmCafePremium from "../images/products/OmCafePremium.png";
import OmCafeRio2 from "../images/products/OmCafeRio2.png";
import OmCafeOrganic from "../images/products/OmCafeOrganic.png";
import OmCafeMirisJutra from "../images/products/OmCafeMirisJutra.png";
import OmCafeMirisJutra2 from "../images/products/OmCafeMirisJutra2.png";

import arrowRight from "../images/arrowRight.png";

import { useEmblaCarousel } from "embla-carousel/react";
import SliderArrow from "./SliderArrow";

import "./style.css";
import { useCallback } from "react";
import { isBrowser } from "react-device-detect";

const Slider = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: "start",
  });
  const [canNext, setCanNext] = useState(true);
  const [canPrev, setCanPrev] = useState(false);

  const scrollNext = useCallback(() => {
    if (!emblaApi) return;
    if (emblaApi.slidesInView().includes(3)) {
      emblaApi.scrollNext();
      setCanNext(false);
      return;
    }
    emblaApi.scrollNext();
    setCanNext(emblaApi?.canScrollNext());
    setCanPrev(emblaApi?.canScrollPrev());
  }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
    setCanNext(emblaApi?.canScrollNext());
    setCanPrev(emblaApi?.canScrollPrev());
  }, [emblaApi]);

  return (
    <>
      <div className="embla" ref={emblaRef}>
        <div className="embla__container">
          <div className="embla__slide">
            <div className="slide-content">
              <div className="image">
                <img alt="" src={OmCafePremium} />
              </div>
              <span className="label">OMCAFÉ PREMIUM</span>
              <a
                href="https://shop.omcafe.net/product/omcafe-premium-500g/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="button">
                  KUPI ODMAH
                  <img
                    src={arrowRight}
                    alt="arrow"
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="embla__slide">
            <div className="slide-content">
              <div className="image">
                <img alt="" src={OmCafeRio2} />
              </div>
              <span className="label">OMCAFÉ RIO</span>
              <a
                href="https://shop.omcafe.net/product/omcafe-rio-500g/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="button">
                  KUPI ODMAH
                  <img
                    src={arrowRight}
                    alt="arrow"
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="embla__slide">
            <div className="slide-content">
              <div className="image">
                <img alt="" src={OmCafeOrganic} />
              </div>
              <span className="label">OMCAFÉ PREMIUM ORGANIC</span>
              <a
                href="https://shop.omcafe.net/product/omcafe-mljevena-kafa-200g-organic-ba-bio-154/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="button">
                  KUPI ODMAH
                  <img
                    src={arrowRight}
                    alt="arrow"
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="embla__slide">
            <div className="slide-content">
              <div className="image">
                <img alt="" src={OmCafeMirisJutra2} />
              </div>
              <span className="label">MIRIS JUTRA BEZ ŠEĆERA</span>
              <a
                href="https://shop.omcafe.net/product/omcafe-miris-jutra-8g-trad-kafa-bez-secera/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="button">
                  KUPI ODMAH
                  <img
                    src={arrowRight}
                    alt="arrow"
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="embla__slide">
            <div className="slide-content">
              <div className="image">
                <img alt="" src={OmCafeMirisJutra} />
              </div>
              <span className="label">MIRIS JUTRA SA ŠEĆEROM</span>
              <a
                href="https://shop.omcafe.net/product/omcafe-miris-jutra-13g-trad-kafa-sa-secerom/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="button">
                  KUPI ODMAH
                  <img
                    src={arrowRight}
                    alt="arrow"
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      {isBrowser && canPrev && (
        <SliderArrow direction="left" onClick={scrollPrev} />
      )}
      {isBrowser && canNext && (
        <SliderArrow direction="right" onClick={scrollNext} />
      )}
    </>
  );
};

export default Slider;
