import React from "react";
import arrowRight from "../images/arrowRight.png";

import "./style.css";

const SliderArrow = ({ direction, onClick }) => {
  return (
    <div
      className={`embla__arrow embla__arrow__${direction}`}
      onClick={onClick}
    >
      <img src={arrowRight} alt="arrow" style={{ objectFit: "contain" }} />
    </div>
  );
};

export default SliderArrow;
