import React from 'react'

// style
import './style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import turska from '../Home/images/turska.jpg'

const TurskaKafa = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={turska} alt="Omcafe - Kafa koja dise" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Novosti</h4>
                            <h2 className="title-single-news">Kafa koja je uništila Osmansko carstvo</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                        <p className="text-single-news">
                        Kafa ili “kahve” je od samog početka bila omiljini napitak vladajuće klase Osmanskog carstva, ali niko nije slutio da će ona ubrzati njegovu propast.
                        </p>
                            <p className="text-single-news">
                            Danas je poznata kao grčka kafa Grcima i Kiparima, bosanska kafa Bosancima, jermenska kafa Armencima, arapska kafa Arapima i turska kafa Turcima, Hrvatima, Abancima i Srbima, međutim “kahve” je sredinom 16. vijeka, bila ista za sve - kafa.                             </p>
                            
                            <p className="text-single-news">
                            U to vrijeme ove teritorije su bile pod okupacijom Osmanskog carstva. Početkom 19. vijeka Osmansko carstvo je počelo da slabi, a manje je poznato da je ”kahve” odigrala značajnu ulogu u njegovoj konačnoj propasti.                            </p>
                            <p className="text-single-news">
                            Kafa je u Tursku došla za vrijeme vladavine sultana Sulejmana Veličanstvenog, kada je čovjek kojeg je poslao da upravlja Jemenom naišao na energično piće, privlačnog mirisa tamo poznatog kao qahwah. Ubrzo ga je predstavio osmanskom dvoru u Carigradu, gdje je postalo omiljeno piće vladajuće klase, a zatim i naroda.                            </p>
                           
                            <p className="text-single-news">
                            Dvorski majstor kafe ili “kahveci usta” imao bi na desetine pomoćnika koji su mu pomagali sameljeti zrna Arabike u fini prah slične teksture kao kod današnjeg instant espressa. Taj fini prah bi se kuvao u bakrenim loncima zvanim “cezves” - džezve.  Dobijeni napitak je bio  gorak, crn i preliven tankim slojem pjene stvorene brzim ulijevanjem, a posluživao se u malim porculanskim šoljicama.                             </p>
                            <p className="text-single-news">
                            Legenda kaže da je Sulejmanova supruga Hurrem Sultanija uzimala svoju “kahve” s čašom vode i slatkom turskom poslaticom kako bi uravnotežila njenu gorčinu, a zanimljivo je da se ovaj način posluživanja kafe u Turskoj primjenjuje i danas.                            </p>
                            <p className="text-single-news">
                            Međutim, nisu svi vjerovali da Kuran dozvoljava muslimanima da piju ovo energično piće privlačnog mirisa. Iako se u islamskim spisima ne spominje kafa, jedan tvrdoglavi moćnik na Sulejmanovom dvoru izdao je fetvu protiv napitka sa obrazloženjem da je zabranjeno konzumiranje bilo čega spaljenog, ali to nije umanjilo privlačnost i popularnost kafe.                             </p>
                            <p className="text-single-news">
                            Prva javna kafana, ili “kahvehane”, osnovana je u Istanbulu tokom 1555. godine od strane dvojice sirijskih trgovaca. Ubrzo je  svaka šesta prodavnica u gradu posluživala kafu. Postepeno “kahve” je bila zastupljena u svim krajeva carstva.</p>
                            <p className="text-single-news">
                            Kafane su muškarcima pružale mogućnost da se okupljaju, omogućavajući im mjesto za druženje, razmjenu informacija, zabavu i obrazovanje. Pismeni članovi društva čitali su naglas vijesti dana; janjičari, pripadnici elitnog kadra osmanskih trupa, planirali su proteste protiv sultana; zvaničnici su razgovarali o sudskim spletkama; trgovci su razmijenili glasine o ratu. Sve je to uticalo na veću informisanost stanovništva, te su se razmjenjivale informacije koje su mogle stvarati probleme za osmansko carstvo:  ideje o pobunama, samoopredeljenjem i pogrešivosti moćnika.
                            </p>
                            <p className="text-single-news">
                            Vlasti su ubrzo počele “kahvehane” doživljavati kao prijetnju. Neki  sultani su  postavljali špijune u kafane kako bi procijenili javno mnijenje ili uticali na njega. 
Drugi, poput Murada IV, sultana sa početka 18.vijeka , pokušali su ih potpuno ugasiti. Međutim, kafane su bile  previše profitabilne. Kada su u 19. vijeku  usijani nacionalistički pokreti proključali u osmanskim zemljama, popularnost kafana je rasla. Etničke grupe u europskim regionima carstva sa istočno-pravoslavnom hrišćanskom većinom počele su da agituju za nezavisnost. Nacionalističke vođe planirale su svoju taktiku i zacementirali saveze u kafanama u Solunu, Sofiji i Beogradu. Njihovi napori podstaknuti kofeinom uspjeli su uspostavljanjem nezavisne Grčke 1821., Srbije 1835. i Bugarske 1878. godine, te je vladavina “kahvea” bila  gotova.
                            </p>
                            <p className="text-single-news">
                            Različite nacije su različito pipremale ovaj napitak i dodavale svoja prepoznatljiva obilježja : Grci ga prave od mastika, biljne smole; Arapi  kafi dodaju kardamom kao začin i poslužuju je u malim šoljicama bez ručki zvanim fildžanima. 
                            </p>
                            <p className="text-single-news">
                            Bilo da se kafa mješa sa nečim ili se koristi u svom obliku,  profil ukusa zavisi od prženja kafe. Srednje do tamno pečena zrna turske sorte daju kafu zemljanog ukusa, dimnih nota i guste teksture. Kada ispijete sve iz šoljice, vidjećete sitna zrna koja prekrivaju dno. 
Efekti kafe djeluju brzo, i podstiču na promjene. Carevi svijeta, pazite se!
                            </p>
                            Izvor: The Economist

                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       
                       </div>







                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TurskaKafa;