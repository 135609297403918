import React from 'react';

// style
import './style.css'

// components
import { Container, Row, Col } from 'react-bootstrap'

// images
import capsule from './images/intro/omcafe-espresso-capsule.png'
import capsuleSingle from './images/intro/omcafe-capsule.png'
import shadow from '../../assets/images/intro/shadow.jpg'

// products
import aparat from './images/products/Omcafe Espresso Capsule Aparat.png'
import strong from './images/products/Omcafe Capsule Strong.png'
import corposo from './images/products/Omcafe Capsule Corposo.png'
import forte from './images/products/Omcafe Capsule Forte.png'
import decaffeinato from './images/products/Omcafe Capsule Decaffeinato.png'
import venezia from './images/products/Omcafe Capsule Venezia.png'
import firenze from './images/products/Omacafe Capsule Firenze.png'

// intensity
import i10 from './images/intensity/i10.svg'
import i9 from './images/intensity/i9.svg'
import i8 from './images/intensity/i8.svg'
import i7 from './images/intensity/i7.svg'
import i6 from './images/intensity/i6.svg'
import i5 from './images/intensity/i5.svg'


const Capsule = () => {
    return (
        <>
            <div>
                <img className="intro-image-capsule" src={capsule} alt="omcafe" />
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} className="text-center">
                        <p className="text-intro-capsule">
                            OMCAFÉ Espresso Capsule pružaju ultimativno hedonističko iskustvo ispijanja espresa. Elegantan su i jednostavan način da konzumirate vašu omiljenu aromu kafe u privatnosti doma ili ugodnosti poslovnog ambijenta. Svaka kapsula sadrži savršen omjer vrhunskih sastojaka, što garantuje jednako zavodljiv ukus pri svakom ispijanju. Zahvaljujući tome, svako naredno ispijanje OMCAFÉ Espresso Capsule zadovoljstvo je identično prvom, zbog kojeg se ovoj kafi uvijek vraćate.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <img className="intro-image-single-capsule" src={capsuleSingle} alt="omcafe" />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <img className="shadow-image-shadow-capsule" src={shadow} alt="omcafe" />
                    </Col>
                </Row>

            </Container>


            <div className="aparat">
                <Container>
                    <Row>
                        <Col lg={6} className="col-align-left-aparat-capsule">
                            <img className="image-aparat-capsule" src={aparat} alt="omcafe" />
                        </Col>
                        <Col lg={6} className="col-align-aparat-capsule">
                            <div className="align-content-aparat-capsule">
                                <h2 className="title-aparat-capsule">OMCAFÉ Espresso Capsule Aparat</h2>
                                <p className="text-aparat-capsule">
                                    OMCAFÉ Espresso Capsule aparat je kompatibilan sa Nespresso kapsulama, te obradom fino mljevenih zrnaca vrhunske kafe kroz samo nekoliko sekundi u dom unosi toplinu o kakvoj svaki ljubitelj kafe sanja. Lako se uklapa u svaki enterijer, a temperatura napitka kojeg proizvodi savršena je za konzumiranje odmah, pružajući zadovoljstvo bez odlaganja.
                                </p>
                                <a href="https://shop.omcafe.net/product/espresso-capsule-aparat-crveni/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                    <button className="button-aparat-capsule">Dodaj u korpu</button>
                                </a>                            
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row>
                    <Col lg={12}>
                        {/* card 1 */}
                        <div className="card-capsule">
                            <Row className="column-reverse-card-capsule">
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-left-card-capsule">
                                        <h2 className="title-card-capsule">Strong</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Strong izdvaja se po jačini, te svojom maksimalno izraženom aromom prija pokloniocima kafe s mnogo karaktera.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i10} alt="omcafe" />
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-capsule-strong/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-capsule btn-strong">Dodaj u korpu</button>
                                        </a>                                       
                                    </div>
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={strong} alt="omcafe" />
                                </Col>
                            </Row>
                        </div>
                        {/* card 2 */}
                        <div className="card-capsule last-card-capsule">
                            <Row>
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={corposo} alt="omcafe" />
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-right-card-capsule">
                                        <h2 className="title-card-capsule">Corposo</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Corposo je perfektna mjera snažnog kofeinskog doživljaja raskošnog ukusa.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i9} alt="omcafe" />
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-capsule-corposo/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-capsule btn-corposo">Dodaj u korpu</button>
                                        </a>                                        
                                    </div>
                                </Col>
                            </Row>
                        </div>





                        {/* card 4 */}
                        <div className="card-capsule">
                            <Row className="column-reverse-card-capsule">
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-left-card-capsule">
                                        <h2 className="title-card-capsule">Forte</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Forte pripada rangu jačih i punijih aroma kafe, zato je idealna u trenucima žudnje za unosom dodatne energije.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i8} alt="omcafe" />
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-capsule-forte/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-capsule btn-forte">Dodaj u korpu</button>
                                        </a>                                        
                                    </div>
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={forte} alt="omcafe" />
                                </Col>
                            </Row>
                        </div>
                        {/* card 5 */}
                        <div className="card-capsule last-card-capsule">
                            <Row>
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={decaffeinato} alt="omcafe" />
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-right-card-capsule">
                                        <h2 className="title-card-capsule">Decaffeinato</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Decaffeinato idealna je za uživaoce kafe, koji ne žele da se odreknu svog omiljenjog napitka, a imaju potrebu da redukuju unos kofeina. U toku ispiranja rastvaračima, ukloni se oko 97% sadržaja kofeina. Posebnom pažnjom prilikom prženja na ovaj način tretiranih zrna, uspjeli smo sačuvati aromu, kao i ostala svojstva kafe.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i7} alt="omcafe" />
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-capsule-decaffeinato/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-capsule btn-decaffeinato">Dodaj u korpu</button>
                                        </a>                                        
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {/* card 6 */}
                        <div className="card-capsule">
                            <Row className="column-reverse-card-capsule">
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-left-card-capsule">
                                        <h2 className="title-card-capsule">Venezia</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Venezia stiče simpatije kod ljubitelja umjerenih nota kafe koji prije svega cijene punoću kvaliteta i njenu glatku zavodljivost ukusa.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i6} alt="omcafe" />
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-capsule-venezia/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-capsule btn-venezia">Dodaj u korpu</button>
                                        </a>                                        
                                    </div>
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={venezia} alt="omcafe" />
                                </Col>
                            </Row>
                        </div>
                        {/* card 7 */}
                        <div className="card-capsule last-card-capsule">
                            <Row>
                                <Col className="col-align-left-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-product-card-capsule" src={firenze} alt="omcafe" />
                                </Col>
                                <Col className="col-align-right-card-capsule" xs={12} sm={12} md={12} lg={6}>
                                    <div className="align-content-right-card-capsule">
                                        <h2 className="title-card-capsule">Firenze</h2>
                                        <p className="text-card-capsule">
                                            OMCAFÉ Espresso Capsule Firenze najnježniji je napitak u svojoj porodici espreso kapsula, a taj epitet ponosno nosi zbog svojog blagog intenziteta i opuštajuće arome kafe.
                                        </p>
                                        <img className="image-intensity-card-capsule" src={i5} alt="omcafe" />
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-capsule-decaffeinato-2/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-capsule btn-firenze">Dodaj u korpu</button>
                                        </a>                                    
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Capsule;