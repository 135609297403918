import React from 'react'

// style
import './style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import aparat from '../Home/images/Omcafe espresso capsule aparat.jpg'
import { Link } from 'react-router-dom';

const NewsAparat = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={aparat} alt="Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">NOVI PROIZVOD</h4>
                            <h2 className="title-single-news">OMCAFÉ Espresso Capsule aparat</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                Ako ste privrženi izvornoj aromi espresa i rado biste svoj ritual unijeli u kućni ili poslovni ambijent, za vas smo ponudu obogatili novim proizvodom, OMCAFÉ Espresso Capsule aparatom i kapsulama. Riječ je o alternativi ulaganju u uređaj profesionalne klase pristupačnoj svakom domaćinstvu koju odlikuju brzina, praktičnost i konzistentnost u procesu pripreme kafe. Zato neka za postojanost vašeg užitka od sada bude zadužen naš espreso aparat koji iz kapsula punjenih savršenim omjerom vrhunskih sastojaka u samo nekoliko sekundi proizvodi napitak opojnih aroma.
                            </p>
                            <p className="text-single-news">
                                OMCAFÉ Espresso Capsule aparat, <span className="italic-news">kompatibilan sa Nespresso kapsulama</span>, zahvaljujući malim dimenzijama, modernom i jednostavnom dizajnu koji dolazi u tri različite boje, lako se uklapa u svaki kuhinjski prostor. Uz poštovanje minimalnih zahtjeva za održavanje, vaš OMCAFÉ Espresso Capsule aparat će dugo i neometano održati konstantan kvalitet napitaka.
                            </p>
                            <p className="text-single-news">
                                <span className="bold-news underline">
                                    <Link to="/capsule" className="reset-link-style">
                                        OMCAFÉ Espresso Capsule
                                    </Link>
                                </span> dolaze u šest različitih aroma, intenziteta i količine kofeina: Firenze, Venezia, Decaffeinato, Forte, Corposo i Strong sadrže male doze kvalitetnih mještavina. Proces odabira, prženja i obrade zrna kafe, kompletiran je u istovjetnim dozama koje vam olakšavaju svakodnevnu pripremu kafe i osiguravaju da svaki gutljaj bude nalik prethodnom.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default NewsAparat;