import React from "react";

// style
import "./style.css";

// components
import { Container, Row, Col } from "react-bootstrap";

// images
import tradicionalna from "./images/tradicionalna.jpg";
import blurLine from "./images/backgrounds/blurLine.png";
import SoljicaKafe from "./images/backgrounds/prefooter.png";
import PremiumTag from "./images/tag.svg";

// products
import OmCafePremium from "./images/products/OmCafePremium.png";
import OmCafeRio from "./images/products/OmCafeRio.png";
import OmCafeOrganic from "./images/products/OmCafeOrganic.png";
import OmCafeMirisJutra from "./images/products/OmCafeMirisJutra.png";
import OmCafeMirisJutra2 from "./images/products/OmCafeMirisJutra2.png";

// text
import OmCafe from "./images/text/OmCafe.png";
import OmCafePremiumText from "./images/text/OmCafePremium.png";
import OmCafeRioText from "./images/text/OmCafeRio.png";
import Slider from "./Slider/Slider";
import BottomPanel from "./BottomPanel/BottomPanel";

const Stats = ({ aroma, uprzenost }) => {
  const array = Array.from({ length: 11 });
  return (
    <div>
      <div>
        <span>AROMA</span>
        <div className="stats-container">
          {array.map((_, index) => (
            <div
              key={index}
              className={
                aroma >= index + 1 ? "filled-circle" : "outlined-circle"
              }
            ></div>
          ))}
        </div>
      </div>
      <div>
        <span>UPRŽENOST</span>
        <div className="stats-container">
          {array.map((_, index) => (
            <div
              key={index}
              className={
                uprzenost >= index + 1 ? "filled-circle" : "outlined-circle"
              }
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Tradicionalna = () => {
  return (
    <div className="tradicionalna-page-root">
      <div>
        <img
          className="intro-image-tradicionalna"
          src={tradicionalna}
          alt="omcafe"
        />
      </div>
      <Container>
        <Row className="justify-content-center">
          <Col lg={10} className="text-center">
            <p className="text-intro-tradicionalna">
              Ono smo što pijemo – to ljubitelji OMCAFÉ kafe najbolje znaju. Jer
              u šoljici svog omiljenog napitka svakodnevno nalaze radost
              dijeljenja, nježnosti, odmor i utjehu, i zadovoljstvo razgovora
              koje hrani dušu, a kafa tijelo. OMCAFÉ je prijatelj kojeg u svoj
              dom uvijek dočekujete s radošću, a on vam pažnju uzvraća svojom
              lojalnošću i kvalitetom. Zato uživati u OMCAFÉ napicima znači
              imati dobar ukus u životu, odnosno umijeće uživanja u dobrim,
              tradicionalnim vrijednostima.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <img
              className="shadow-intro-image-tradicionalna"
              src={blurLine}
              alt="omcafe"
            />
          </Col>
        </Row>
      </Container>

      <Container className="cards-container">
        <Row className="right-aligned card-tradicionalna">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="col-left-card-tradicionalna"
            style={{ position: "relative" }}
          >
            <img
              className="image-card-tradicionalna"
              src={OmCafePremium}
              alt="omcafe"
            />
            <img
              style={{ position: "absolute", top: 32, right: 24 }}
              className="image-card-tag"
              src={PremiumTag}
              alt="omcafe"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="col-right-card-tradicionalna"
          >
            <div className="align-content-card-tradicionalna">
              <div className="header-card" style={{ gap: 0 }}>
                <img
                  className="header-card-tradicionalna"
                  src={OmCafe}
                  alt="omcafe"
                />
                <img
                  className="header-card-premium-tradicionalna"
                  src={OmCafePremiumText}
                  alt="omcafe"
                />
              </div>
              <p className="text-card-tradicionalna">
                Prepoznatljiva po svom mirisu i ukusu, nezaobilazan je gost
                porodičnih okupljanja i dio svakog s ljubavlju odabranog
                poklona. Jedinstvenu mrežu aroma duguje pažljivo sačinjenoj
                mješavini arabike, koja spada u višu klasu zrna uzgojenih na
                kišovitim i sjenovitim visijama Latinske Amerike, i robuste koja
                dolazi iz vrućih krajeva i odlikuje se postojanošću i većim
                intenzitetom ukusa zahvaljujući dvostruko većoj koncentraciji
                kofeina.
              </p>
              <Stats aroma={10} uprzenost={7} />
            </div>
          </Col>
        </Row>

        <Row className="card-tradicionalna">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="col-left-card-tradicionalna"
          >
            <img
              className="image-card-tradicionalna"
              src={OmCafeRio}
              alt="omcafe"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="col-right-card-tradicionalna"
          >
            <div className="align-content-card-tradicionalna">
              <div className="header-card" style={{ gap: 0 }}>
                <img
                  className="header-card-tradicionalna"
                  src={OmCafe}
                  alt="omcafe"
                />
                <img
                  className="header-card-premium-tradicionalna"
                  src={OmCafeRioText}
                  alt="omcafe"
                />
              </div>
              <p className="text-card-tradicionalna">
                Naša tradicionalna domaća kafa proizvodi se u dva oblika: OMCAFÉ
                Premium i OMCAFÉ Rio. Osnovna razlika između ove dvije vrste
                leži u intenzivnijem ukusu aromatičnije OMCAFÉ Premium, u odnosu
                na OMCAFÉ Rio, koja je jače upržena, te i više prija
                ljubiteljima jačih aroma kafe. U svakoj kašičici ove fino
                mljevene kafe sljubljena su bogatstva plantaža Latinske Amerike
                i Afrike, a njihovo zajedništvo pred vama stvara pravo malo
                aromatično čudo.
              </p>
              <Stats aroma={9} uprzenost={8} />
            </div>
          </Col>
        </Row>
        <Row className="right-aligned card-tradicionalna">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="col-left-card-tradicionalna"
          >
            <img
              className="image-card-tradicionalna"
              src={OmCafeOrganic}
              alt="omcafe"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="col-right-card-tradicionalna"
          >
            <div className="align-content-card-tradicionalna">
              <div className="header-card">
                <img
                  className="header-card-tradicionalna"
                  src={OmCafe}
                  alt="omcafe"
                />
                <span className="subtitle-tradicionalna">
                  Premium Organic Coffee
                </span>
              </div>
              <p className="text-card-tradicionalna">
                Tradicionalna mješavina organske pržene mljevene kafe arabike i
                robuste 100% organske proizvodnje. Proizvedena po principima
                organskog uzgoja i distribuisana sertifikovanim lancem pravedne
                trgovine Fair Trade (Fair for life) 100% ukupnih sastojaka.
                Pakovana u zaštitnoj atmosferi. Od plantaže do vaše šoljice,
                svaki detalj je doveden do savršenstva.
              </p>
              <Stats aroma={10} uprzenost={6} />
            </div>
          </Col>
        </Row>

        <Row className=" card-tradicionalna">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="col-left-card-tradicionalna"
          >
            <img
              className="image-card-tradicionalna"
              src={OmCafeMirisJutra2}
              alt="omcafe"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="col-right-card-tradicionalna"
          >
            <div className="align-content-card-tradicionalna">
              <div className="header-card">
                <img
                  className="header-card-tradicionalna"
                  src={OmCafe}
                  alt="omcafe"
                />
                <span className="subtitle-tradicionalna">
                  MIRIS JUTRA BEZ ŠEĆERA
                </span>
              </div>
              <p className="text-card-tradicionalna">
                Po jutru se dan poznaje, a dobre navike po kafi bez šećera. Zato
                ljubitelji kafe koji uživaju u njenoj originalnoj oporosti mogu
                svoj tradicionalni napitak spremiti lako i brzo. Miris jutra bez
                šećera je vaše džepno izdanje zadovoljstva.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="right-aligned card-tradicionalna">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="col-left-card-tradicionalna"
          >
            <img
              className="image-card-tradicionalna"
              src={OmCafeMirisJutra}
              alt="omcafe"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="col-right-card-tradicionalna"
          >
            <div className="align-content-card-tradicionalna">
              <div className="header-card">
                <img
                  className="header-card-tradicionalna"
                  src={OmCafe}
                  alt="omcafe"
                />
                <span className="subtitle-tradicionalna">
                  MIRIS JUTRA SA ŠEĆEROM
                </span>
              </div>
              <p className="text-card-tradicionalna">
                Ima li boljeg mirisa jutra od šoljice vruće kafe? I kad dnevna
                rutina od ranih časova ubrza svoj tempo, ne morate se odreći
                svoje omiljene navike – uživanja u aromi tradicionalne crne
                kafe. Miris jutra u kesici je elegantan način da u svim
                okolnostima ostanete vijerni sopstvenoj tradiciji.
              </p>
            </div>
          </Col>
        </Row>

        <Row style={{ position: "relative", marginLeft: 0, marginRight: 0 }}>
          <Slider />
        </Row>

        <Row>
          <BottomPanel />
        </Row>
      </Container>
      <div>
        <img
          src={SoljicaKafe}
          alt="omcafe"
          className="tradicionalna-prefooter"
        />
      </div>
    </div>
  );
};
export default Tradicionalna;
