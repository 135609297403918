import React, { useState } from "react";

// components
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";

// style
import './style.css';

// images
import background1 from "./images/background-1.jpg";
import background2 from "./images/background-2.jpg";
import klubLjubiteljaOmcafe from "./images/klub-lubitelja-omcafe.jpg";
import OrganicCoffee from "./images/Omcafe-organic-coffee.jpg";
import aparat from './images/Omcafe espresso capsule aparat.jpg'
import prodavnica from './images/Omcafe online prodavnica.jpg'
import tarik from './images/Omcafe Espresso Capsule - Tarik Filipovic.jpg'
import zenskePricePaket from './images/poklon-paket-omcafe-zenske-price.jpg'
import kafaKojaDise from './images/kafa-koja-dise.jpg'
import slika1 from './images/turska.jpg'
import tarik2 from './images/tarik-photo.jpg'

// icons
import Close from '../../components/Navigation/images/Close'
// import Slider from "./Slider";
const Home = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            {/* <Slider /> */}

            <div className="bg-products1">
                <Container fluid>
                    <Row>
                     <Col xs={12} sm={12} md={12} lg={3} className="p-0 col-product-home-0">
                            <div className="product-home-0">
                                <Link to="/tradicionalna-kafa" className="reset-link-style">
                                    <h1 className="title-product-home">Tradicionalna</h1>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3} className="p-0  col-product-home-1">
                            <div className="product-home-1">
                                <Link to="/espresso-kafa" className="reset-link-style">
                                    <h1 className="title-product-home">Espresso</h1>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3} className="p-0  col-product-home-2">
                            <div className="product-home-2">
                                <Link to="/instant-kafa" className="reset-link-style">
                                    <h1 className="title-product-home">Instant</h1>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3} className="p-0  col-product-home-3">
                            <div className="product-home-3">
                                <Link to="/espresso-capsule" className="reset-link-style">
                                    <h1 className="title-product-home">Capsule</h1>
                                </Link>
                            </div>
                      </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-home">
                <Container fluid>

                    <Row>
                        <Col lg={6} className="p-0">
                            <div className="position-card-home">
                                <img src={background1} alt="Omcafe BIH" className="image-card-home" />
                            </div>
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">OMCAFÉ</h4>
                                <h2 className="title-card-home">Priča o posebnosti</h2>
                                <p className="text-card-home">
                                    Priča o šoljici vaše kafe počinje obilaskom gotovo cijelog svijeta. U potrazi za najboljim vrstama, stručnjaci iz naše kompanije posjećuju pojedinačne uzgajivače sirove kafe i grade dugoročne, kao i lične odnose sa njima. Odlični klimatski uslovi za uzgoj, kao i duga tradicija kultivisanja ove biljke, opredijelili su nas za visoke predele pojasa Ekvatora. Da bi se izabrale najbolje vrste sirove kafe, naši eksperti degustiraju svake godine preko 400 probnih prženja. Kafa koju uvozimo i način na koji je pržimo, neodvojivo su povezani jedno sa drugim. Da bi iz naših najkvalitetnijih zelenih zrna kafe izmamili cjelokupan spektar aroma, razvili smo poseban način mljevenja i prženja, a koji i dalje usavršavamo. Ovaj proces traje već od ranih devedesetih godina XX vijeka, kada je ujedno i osnovana kompanija „Bom Impeks“, propoznatljiva sa svojim brendom OMCAFÉ, prvim privatnim u to vrijeme u BiH.
                                    </p>
                                <Link to="/o-nama" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Prikaži više
                                        </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className="column-reverse-card-home">
                    <Col lg={6} className="p-0">
                            <img src={background2} alt="Omcafe BIH" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">OMCAFÉ AKTUELNOSTI</h4>
                                <h2 className="title-card-home">Vijesti sa mirisom kafe</h2>
                                <p className="text-card-home">
                                    Vaša ljubav prema kafi se ne mijenja, ali svijet proizvodnje i plasiranja novih, uzbudljivih kofeinskih kombinacija se razvija tik kraj vas. Zašto svoju omiljenu naviku ne biste obogatili iznenađujućim novinama? Pratite Omcafé vijesti i budite uvijek u toku sa našim aktivnostima.
                                </p>
                                <Link to="/novosti" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Prikaži novosti
                                        </button>
                                </Link>
                            </div>
                        </Col>
                      
                    </Row>
                    <Row>
                        <Col lg={6} className="p-0">
                            <img src={klubLjubiteljaOmcafe} alt="Omcafe BIH" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">OMCAFÉ NEWSLETTER</h4>
                                <h2 className="title-card-home">Klub ljubitelja - mjesto koje vam nudi više</h2>
                                <p className="text-card-home">
                                    Izvrsnost nije samo u otkrivanju vrhunske mješavine kafe već i u nastojanju da bude dostupna što većem broju poklonika ovog aromatičnog napitka. Zato smo osnovali Klub ljubitelja, zajednicu u kojoj se vijesti o novim proizvodima, akcijama i pogodnostima pri kupovini šire kao miris tek skuvane kafe. Dosad smo okupili više od 20.000 članova vjernih Omcafé kvalitetu. Kliknite na dugme ispod i postanite dio naše zajednice.
                                </p>
                                <button type="button" className="button-card-home" onClick={handleShow}>
                                    Želim da se učlanim
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="column-reverse-card-home">
                    <Col lg={6} className="p-0">
                            <img src={tarik2} alt="Tarik Filipović: 6 ključnih faktora za harizmu koja osvaja" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            
                            <div className="card-home">
                                <h4 className="subtitle-card-home">Novosti</h4>
                                <h2 className="title-card-home">Tarik Filipović: 6 ključnih faktora za harizmu koja osvaja</h2>
                                <p className="text-card-home">
                                Tačno je da se svi nekada osjećamo bezvoljno. Zar ne? Ali, tačno je i da u tome nema baš ništa loše ukoliko naučimo negativnu energiju zamijeniti onom stimulativnom i pozitivnom. A to nikada nije bilo lakše, jer – naša energija ima miris koji plijeni i ukus koji osvaja i najzahtjevnije ljubitelje kafe kakav je i naš Tarik. 
                                </p>
                                <Link to="/tarik-filipovic-6-kljucnih-faktora" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Nastavi čitanje
                                    </button>
                                </Link>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row>
                    <Col lg={6} className="p-0">
                            <img src={slika1} alt="Omcafe - Kafa koja diše" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            
                            <div className="card-home">
                                <h4 className="subtitle-card-home">Novosti</h4>
                                <h2 className="title-card-home">Kafa koja je uništila Osmansko carstvo</h2>
                                <p className="text-card-home">
                                Kafa ili “kahve” je od samog početka bila omiljini napitak vladajuće klase Osmanskog carstva, ali niko nije slutio da će ona ubrzati njegovu propast.
                                </p>
                                <Link to="/kafa-koja-je-unistila-osmansko-carstvo" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Nastavi čitanje
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className="column-reverse-card-home">
                    <Col lg={6} className="p-0">
                            <img src={kafaKojaDise} alt="Omcafe - Kafa koja diše" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            
                            <div className="card-home">
                                <h4 className="subtitle-card-home">Novosti</h4>
                                <h2 className="title-card-home">Kafa koja „diše“</h2>
                                <p className="text-card-home">
                                    Ako ste veliki ljubitelj kafe, sigurno ste primjetili da se na vrećicama za kafu nalazi jednosmjerni ventil za degasifikaciju koji omogućava zrnima kafe da „dišu“.
                                </p>
                                <Link to="/kafa-koja-dise" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Nastavi čitanje
                                    </button>
                                </Link>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row >
                    <Col lg={6} className="p-0">
                            <img src={zenskePricePaket} alt="Poklon paket Ženske priče - Omcafe BIH" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">Novosti</h4>
                                <h2 className="title-card-home">Poklon paket "Ženske priče"</h2>
                                <p className="text-card-home">
                                    Obradujte ženu koju volite, zahvalite joj i recite nešto lijepo – poklonite joj paket „Ženskih priča“, sa 350 g Premium kafe i posebno dizajniranom šoljicom.
                                    Uskoro dostupno u svim većim prodajnim mjestima.
                                </p>
                                <Link to="/zenske-price" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Pročitaj priče
                                    </button>
                                </Link>
                            </div>
                        </Col>
                       
                    </Row>
                    <Row className="column-reverse-card-home">
                        <Col lg={6} className="p-0">
                            <img src={tarik} alt="Omcafe Espresso Capsule Tarik Filipović" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">Novosti</h4>
                                <h2 className="title-card-home">Tarik Filipović je novi ambasador brenda</h2>
                                <p className="text-card-home">
                                    Glumac i televizijski voditelj Tarik Filipović u narednom periodu biće zaštitno lice OMCAFÉ Espresso
                                    Capsula. Počevši od 19. oktobra, Tarik Filipović će biti predstavljen u novoj OMCAFÉ Espresso Capsule
                                    marketinškoj kampanji koja nas poziva da „izaberemo svoj užitak“, putem televizijskog, štampanog i
                                    digitalnog oglašavanja.
                                </p>
                                <Link to="/tarik-filipovic-novi-brend-ambasador" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Nastavi čitanje
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                    <Col lg={6} className="p-0">
                            <img src={prodavnica} alt="Omcafe BIH" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">Novo - OMCAFÉ online prodavnica</h4>
                                <h2 className="title-card-home">OMCAFÉ online prodavnica</h2>
                                <p className="text-card-home">
                                    Dragi kafoljupci, od sada vaše omiljene OMCAFÉ proizvode možete pronaći na novootvorenoj web prodavnici na našem sajtu. Iz sigurnosti vašeg doma naručite željene količine i ubrzo ih očekujte na vašoj adresi uz plaćanje pouzećem. Prodavnici pristupite na našem sajtu: <u>shop.omcafe.net</u> i uživajte u kupovini.
                                </p>
                                <a href="https://shop.omcafe.net/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Posjeti prodavnicu
                                    </button>
                                </a>
                            </div>
                        </Col>
                        
                    </Row>
                    <Row className="column-reverse-card-home">
                        <Col lg={6} className="p-0">
                            <img src={aparat} alt="Omcafe BIH" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">Novi proizvod</h4>
                                <h2 className="title-card-home">OMCAFÉ Espresso Capsule aparat</h2>
                                <p className="text-card-home">
                                    Ako ste privrženi izvornoj aromi espresa i rado biste svoj ritual unijeli u kućni ili poslovni ambijent, za vas smo ponudu obogatili novim proizvodom, OMCAFÉ Espresso Capsule aparatom i kapsulama. Riječ je o alternativi ulaganju u uređaj profesionalne klase pristupačnoj svakom domaćinstvu koju odlikuju brzina, praktičnost i konzistentnost u procesu pripreme kafe. Zato neka za postojanost vašeg užitka od sada bude zadužen naš espreso aparat koji iz kapsula punjenih savršenim omjerom vrhunskih sastojaka u samo nekoliko sekundi proizvodi napitak opojnih aroma.
                                    </p>
                                <Link to="/omcafe-espresso-capsule-aparat" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Nastavi čitanje
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg={6} className="p-0">
                            <img src={OrganicCoffee} alt="Omcafe BIH" className="image-card-home" />
                        </Col>
                        <Col lg={6} className="p-0">
                            <div className="card-home">
                                <h4 className="subtitle-card-home">Novi proizvod - organska kafa</h4>
                                <h2 className="title-card-home">Kad priroda i tijelo kažu hvala</h2>
                                <p className="text-card-home">
                                    Misliti na svoju ali i dobrobit okoline čak i u trenucima ličnog uživanja odavno je postao imperativ u ishrani. Namirnice koje svakodnevno unosimo, mnoge od njih proizvedene na veoma udaljenim krajevima planete, dugotrajnom konzumacijom utiču na naš organizam, zbog čega je važno posvetiti posebnu pažnju njihovom kvalitetu odnosno načinu proizvodnje. Osim toga, sam proces proizvodnje podrazumijeva ne samo nastanka proizvoda, već cio trgovinski lanac od proizvođača do potrošača, u kojem mnogi učesnici ne dobiju pravednu raspodjelu sredstava. To se posebno odnosi na siromašne zemlju sa često neuređenim radnim pravima pa čak i eksploatacijom dječijeg rada.
                                    </p>
                                <Link to="/omcafe-organska-kafa" className="reset-link-style">
                                    <button type="button" className="button-card-home">
                                        Nastavi čitanje
                                        </button>
                                </Link>
                            </div>
                        </Col>
                        
                    </Row>




                </Container>
            </div>


            <Modal
                centered
                size="lg"
                show={show}
                onHide={handleClose}
                keyboard>
                <Modal.Body>
                    <Close className="close-icon-modal-home" onClick={handleClose} />
                    <h4 class="subtitle-modal-card-home">OMCAFÉ NEWSLETTER</h4>
                    <h3 className="title-modal-home">OMCAFÉ Klub ljubitelja</h3>
                    <form>
                        <Row>
                            <Col sm={6}>
                                <input className="input-modal-home" type="text" placeholder="Ime i prezime" />
                            </Col>
                            <Col sm={6}>
                                <input className="input-modal-home" type="text" placeholder="Adresa" />
                            </Col>
                            <Col sm={6}>
                                <input className="input-modal-home" type="text" placeholder="Grad" />
                            </Col>
                            <Col sm={6}>
                                <input className="input-modal-home" type="text" placeholder="Broj mobilnog telefona" />
                            </Col>
                            <Col sm={6}>
                                <input className="input-modal-home" type="email" placeholder="E-mail" />
                            </Col>
                            <Col sm={6}>
                                <input className="input-modal-home" type="text" placeholder="Datum rođenja" />
                            </Col>
                            <Col sm={12}>
                                <button className="button-modal-card-home">
                                    Učlani me
                                </button>
                            </Col>
                            <Col sm={12}>
                                <p className="text-form-modal-home">Popunjavanjem ove forme potvrđujem da se moji podaci mogu koristiti za bazu podataka i u svrhu marketing aktivnosti kompanije Bom Impeks d.o.o.</p>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Home;