import React from 'react'

// style
import './style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import organska from '../Home/images/Omcafe-organic-coffee.jpg'

const NewsOrganskaKafa = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={organska} alt="Omcafe BIH" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">NOVI PROIZVOD - ORGANSKA KAFA</h4>
                            <h2 className="title-single-news">Kad priroda i tijelo kažu hvala</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                Misliti na svoju ali i dobrobit okoline čak i u trenucima ličnog uživanja odavno je postao imperativ u ishrani. Namirnice koje svakodnevno unosimo, mnoge od njih proizvedene na veoma udaljenim krajevima planete, dugotrajnom konzumacijom utiču na naš organizam, zbog čega je važno posvetiti posebnu pažnju njihovom kvalitetu odnosno načinu proizvodnje. Osim toga, sam proces proizvodnje podrazumijeva ne samo nastanka proizvoda, već cio trgovinski lanac od proizvođača do potrošača, u kojem mnogi učesnici ne dobiju pravednu raspodjelu sredstava. To se posebno odnosi na siromašne zemlju sa često neuređenim radnim pravima pa čak i eksploatacijom dječijeg rada.
                            </p>
                            <p className="text-single-news">
                                Zbog toga, kompanija OMCAFÉ, koja na našem tržištu uspješno posluje već tri decenije, na svoj način odgovorila je ovim izazovima savremenog doba, sertifikovanjem svoja dva nova proizvoda etiketama organske proizvodnje i pravedne trgovine – Fair Trade odnosno Fair for Life. U nastojanju da daju svoj doprinos u društveno odgovornom ponašanju u trgovini sa proizvođačima iz siromašnijih sredina i zemalja, te da svojim potrošačima ponude kafu što čistijeg porijekla i izuzetnih nutritivnih vrijednosti, kompanija OMCAFÉ na tržište je iznijela OMCAFÉ Premium Organic i OMCAFÉ Espresso Organic.
                            </p>
                            <p className="text-single-news">
                                Iza jednostavnog naziva organske kafe krije se veliki podvig sertifikacije. Drugim riječima, to znači ne samo da je biljka uzgojena bez upotrebe agrohemijskih proizvoda, već cio proizvodno-trgovinski lanac, od uzgoja preko transporta do prženja, mora biti ovjeren kao organski.  Za taj dug i provjeren put Međunarodna organizacija za sertifikaciju organskih i ekoloških proizvoda – Ecocert, nagradila je OMCAFÉ kompaniju ovom vrijednom oznakom. Pored toga, nosilac je Fair for Life sertifikata zasnovanog na fer trgovini (Fair Trade), odnosno na poštenim poslovnim odnosima, fer uslovima rada i poštovanju životne sredine unutar i duž cijelog lanca snabdijevanja.
                            </p>
                            <p className="text-single-news">
                                Ljubitelji ukusa tradicionalne OMCAFÉ Premium kafe u njenoj organskoj verziji mogu uživati u savršenoj mješavini zrna arabike i robuste, porijeklom iz Indije, Meksika i Nikaragve.  OMCAFÉ Espresso Organic obrađen je na stepen najtamnije uprženosti, a obje kafe proizvedene su u 100% organskim uslovima, kao rezultat 100% pravednog poslovanja.
                            </p>
                            <p className="text-single-news">
                                Kada kupujete organsku kafu, činite dobro za svoj organizam, ali i podržavate poljoprivrednike koji su posvećeni životnoj sredini i pomažu u promociji biološke raznolikosti.
                            </p>
                            <p className="text-single-news">
                                <span className="italic-news"><span className="bold-news">Unaprijedite sebe i vašu okolinu, uživajte pametno. Poželite dobro svima.</span></span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default NewsOrganskaKafa;