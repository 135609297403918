import React from 'react'
import './style.css'

// components
import { Container, Row, Col } from 'react-bootstrap'
// images
import espresso from './images/espresso-omcafe.jpg'
import Coffee from '../../assets/images/intro/Coffee'
import shadow from '../../assets/images/intro/shadow.jpg'

// products images
import strong from './images/products/strong-espresso-omcafe.png'
import corposo from './images/products/corposo-espresso-omcafe.png'
import firenze from './images/products/firenze-espresso-omcafe.png'
import forte from './images/products/forte-espresso-omcafe.png'
import venezia from './images/products/venezia-espresso-omcafe.png'
import organic from './images/products/organic-espresso-omcafe.png'

const Espresso = () => {
    return (
        <>
            <div>
                <img className="intro-image" src={espresso} alt="omcafe" />
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} className="text-center">
                        <p className="text-intro">
                            Efekat koji espreso ostavlja na vaše nepce dostojan je njegovog imena; to je brz i visokoaromatičan kofeinski doživljaj u malom sadržaju šoljice. Moćan i ukusan, predstavlja veoma koncentrisanu ekstrakciju zrna kafe koja dugo ostaje u čulima. Svaka bobica kafe u sebi krije obilje ukusa, ali naglašavanje onih najukusnijih elemenata upavo je tajna postupka prženja potrebnog za nastanak idealnog espresa. Zato je stvaranja nove vrijednosti zadatak povjeren samo znalcima.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Coffee className="intro-image-beans" />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <img className="intro-image-shadow" src={shadow} alt="omcafe" />
                    </Col>
                </Row>


                {/* card organic */}
                <Row>
                    <Col>
                        <div className="card-espresso background-organic-card-espresso padding-left-card-espresso">
                            <Row className="column-reverse-card-espresso">
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-left-card-espresso">
                                    <div className="align-content-left-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Organic</h2>
                                        <p className="text-card-espresso">Opojna mješavina kafa arabike i robuste sertifikovane 100% organske proizvodnje, pakovana
                                        u zaštitnoj atmosferi.</p>
                                        <p className="sub-text-card-espresso">KOLIČINA: 1000g</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-1000g-organic-ba-bio-154/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-espresso">Dodaj u korpu</button>
                                        </a>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <img className="image-card-espresso" src={organic} alt="omcafe espresso coffee" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                {/* card 1 */}
                <Row>
                    <Col>
                        <div className="card-espresso background-strong-card-espresso padding-right-card-espresso">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-card-espresso" src={strong} alt="omcafe espresso coffee" />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <div className="align-content-right-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Strong</h2>
                                        <p className="text-card-espresso">OMCAFÉ Espresso Strong je intenzivna, jaka kafa prepoznatljiva po svojoj punoći i kremastoj teksturi.</p>
                                        <p className="sub-text-card-espresso">KOLIČINA: 1000g</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-strong/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-espresso">Dodaj u korpu</button>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                {/* card 2 */}
                <Row>
                    <Col>
                        <div className="card-espresso background-corposo-card-espresso padding-left-card-espresso">
                            <Row className="column-reverse-card-espresso">
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-left-card-espresso">
                                    <div className="align-content-left-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Corposo</h2>
                                        <p className="text-card-espresso">OMCAFÉ Espresso Corposo je egzotična mješavina u kojoj postupak obrade zrna Espresso Roast dolazi do posebnog izražaja.</p>
                                        <p className="sub-text-card-espresso">KOLIČINA: 1000g</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-corposo/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-espresso">Dodaj u korpu</button>
                                        </a>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <img className="image-card-espresso" src={corposo} alt="omcafe espresso coffee" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>


                {/* card 3 */}
                <Row>
                    <Col>
                        <div className="card-espresso background-forte-card-espresso padding-right-card-espresso">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-card-espresso" src={forte} alt="omcafe espresso coffee" />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <div className="align-content-right-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Forte</h2>
                                        <p className="text-card-espresso">OMCAFÉ Espresso Forte je izražajne punoće, a posebno slatkastu nijansu arome joj donosi Italian Roast.</p>
                                        <p className="sub-text-card-espresso">KOLIČINA: 1000g</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-forte/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-espresso">Dodaj u korpu</button>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>


                {/* card 4 */}
                <Row>
                    <Col>
                        <div className="card-espresso background-venezia-card-espresso padding-left-card-espresso">
                            <Row className="column-reverse-card-espresso">
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-left-card-espresso">
                                    <div className="align-content-left-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Venezia</h2>
                                        <p className="text-card-espresso">OMCAFÉ Espresso Venezia prefinjenog je ukusa, umjerene uprženosti i kremaste teksture.</p>
                                        <p className="sub-text-card-espresso">KOLIČINA: 1000g</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-venezia/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-espresso">Dodaj u korpu</button>
                                        </a>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <img className="image-card-espresso" src={venezia} alt="omcafe espresso coffee" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                {/* card 5 */}
                <Row>
                    <Col>
                        <div className="card-espresso background-firenze-card-espresso padding-right-card-espresso last-card-espresso">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <img className="image-card-espresso" src={firenze} alt="omcafe espresso coffee" />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-align-right-card-espresso">
                                    <div className="align-content-right-card-espresso">
                                        <h2 className="title-card-espresso">OMCAFÉ Espresso Firenze</h2>
                                        <p className="text-card-espresso">OMCAFÉ Espresso Firenze nešto blažeg je intenziteta i sadrži visok postotak najkvalitetnije arabike.</p>
                                        <p className="sub-text-card-espresso">KOLIČINA: 1000g</p>
                                        <a href="https://shop.omcafe.net/product/omcafe-espresso-firenze/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                            <button className="button-card-espresso">Dodaj u korpu</button>
                                        </a>                                       
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Espresso;