import React, { useState } from 'react'
import './style.css'
import Logo from './images/Logo'
import Close from './images/Close'
import IzgovorZaRazgovor from './images/IzgovorZaRazgovor'

import IconHamburger from './images/IconHamburger'
import Language from './images/Language'



import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Navigation = () => {
    const [isActive, setIsActive] = useState(false);
    return (
        <>
            <div className={`menu ${isActive ? 'menu--active' : ''}`}>
                <Container>
                    <Row className="form-inline">
                        <Col xs={10} className="col-logo-menu">
                            <Logo className="logo-menu" /> <IzgovorZaRazgovor className="text-logo-menu" />
                        </Col>
                        <Col xs={2} className="col-close-menu">
                            <Close className="close-menu" onClick={() => setIsActive(!isActive)} />
                        </Col>
                    </Row>
                    <div className="position-menu">
                        <Row>
                            <Col xs={5} sm={5} md={5} lg={4}>
                                <h4 className="title-menu">Omcafé</h4>
                                <Link to="/" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Početna
                                    </p>
                                </Link>
                                <Link to="/o-nama" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        O nama
                                    </p>
                                </Link>
                                <Link to="/katalog" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Katalog
                                    </p>
                                </Link>
                                <Link to="/kontakt" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Kontakt
                                    </p>
                                </Link>
                                <Link to="/novosti" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Novosti
                                    </p>
                                </Link>
                                <a href="https://shop.omcafe.net/" target="_blank" rel="noopener noreferrer" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Web shop
                                    </p>
                                </a>
                                <Link to="/espresso-aparat-vr" className="reset-link-style">
                                    <p className="link-menu" style={{color: "#c59361"}} onClick={() => setIsActive(!isActive)}>
                                        VR prikaz
                                    </p>
                                </Link>
                            </Col>
                            <Col xs={7} sm={7} md={7} lg={5}>
                                <h4 className="title-menu">Proizvodi</h4>
                                <Link to="/tradicionalna-kafa" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Tradicionalna
                                    </p>
                                </Link>
                                <Link to="/espresso-kafa" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Espresso
                                    </p>
                                </Link>
                                <Link to="/instant-kafa" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Instant
                                    </p>
                                </Link>
                                <Link to="/espresso-capsule" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Capsule
                                    </p>
                                </Link>
                            </Col>
                            <Col xs={7} sm={7} md={7} lg={3} className="language">
                                <h4 className="title-menu form-inline"> <Language className="icon-language" />Jezik</h4>
                                <a href="https://en.omcafe.net/" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        English
                                    </p>
                                </a>
                                <Link to="/" className="reset-link-style">
                                    <p className="link-menu" onClick={() => setIsActive(!isActive)}>
                                        Srpski
                                    </p>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <div className="navigation">
                <Container>
                    <div className="items-position">
                        <Link to="/" className="reset-link-style">
                            <Logo className="logo" />
                        </Link>
                        <IconHamburger className="icon-hamburger" onClick={() => setIsActive(!isActive)} />
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Navigation