import React from 'react'

// style
import './style.css'

// components
import { Container, Col, Row } from 'react-bootstrap';

// images
import tarik from '../Home/images/tarik-photo.jpg'

const TarikFilipovic6KljucnihFaktora = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <img src={tarik} alt="Omcafe Espresso Capsule Tarik Filipović" className="image-single-news" />
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="align-content-single-news">
                            <h4 className="subtitle-single-news">Novosti</h4>
                            <h2 className="title-single-news">Tarik Filipović: 6 ključnih faktora za harizmu koja osvaja</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>“
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="content-single-news">
                            <p className="text-single-news">
                                Tačno je da se svi nekada osjećamo bezvoljno. Zar ne? Ali, tačno je i da u tome nema baš ništa loše ukoliko naučimo negativnu energiju zamijeniti onom stimulativnom i pozitivnom. A to nikada nije bilo lakše, jer – naša energija ima miris koji plijeni i ukus koji osvaja i najzahtjevnije ljubitelje kafe kakav je i naš Tarik. 
                            </p>
                            <p className="text-single-news">
                                „Najbolje od svega je to što od šest faktora, možete izabrati samo jedan, a na vama je da se odlučite kojim OMCAFÉ Espresso Capsulama ćete pokloniti svoje srce“, priča nam on. 
                            </p>
                            <p className="text-single-news">
                                Firenze, Venezia, Decaffeinato, Forte, Corposo i Strong – tako različitog intenziteta, a opet protkane istim koncem čistog zadovoljstva koje se krije u zrnu kafe. 
                            </p>
                            <p className="text-single-news">
                                „Ja ne uspijevam da izdvojim jedne omiljene, jer u različitim situacijama, ali i u zavisnosti od toga kako se osjećam biram i Capsule koje mi se za taj trenutak čine najbolje, i stvarno, nema greške.“ 
                            </p>
                            <p className="text-single-news">
                                Ako vam treba nježan napitak blage jačine – uzmite Firenze, a Venezia Capsule su tu za za zaljubljenike u samo malo više naglašene, a opet jako umjerene note kafe. Za one koji se ne žele odvojiti od najpoznatijeg napitka, a opet žele da redukuju unos kofeina – tu je Decaffeinato. Dodatna energija se krije u Forte Capsulama jake arome i punijeg okusa, a raskoš snažnog kofeinskog doživljaja je u Corposo Capsulama. Za kraj čuvamo Strong Capsule najjače po svemu – i po intenzitetu i po aromi koja je rezervisana za one odvažne i karakterne. Uz harmoniju, koju čine OMCAFÉ Espresso Capsule i za njih kompatibilan aparat, dom postaje topliji, radno okruženje inspirativnije, doza sreće raste, a mirisne note prožimaju okruženje. 
                            </p>
                            <p className="text-single-news">
                                Pa tako, ukoliko se ne možete odlučiti koju od šest vrsta kapsula izabrati, za početak jednostavno birajte vašu boju. Energija neće izostati! 
                            </p>
                            <p className="text-single-news">
                                Tarik nam je zaista najbolje i sa posebnom pažnjom opisao OMCAFÉ Espresso Capsule: „Raspoloženja su boje, njih vezujemo za osjećanja, a kafa je jedno od najljepših doživljaja koje krije jedan dan. Svako ima svoju omiljenu, ali ipak je harizma najsnažnija ako sve boje pustimo u naš život, a jedno je sigurno - čula će nam biti zahvalna.“
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TarikFilipovic6KljucnihFaktora;