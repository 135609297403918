import React from 'react'

import katalogpdf from './Katalog.pdf';
import {isMobile} from "react-device-detect";
import {MobileCatalog} from './mobile-katalog';

const Katalog = () => {
    if (isMobile) return <MobileCatalog />;
    
    return (
        <object data={katalogpdf}  style={{
            width: '100%',
            height: 'calc(100vh - 50px)'
        }} type="application/pdf">
        <iframe title="Katalog" src={katalogpdf} />
        </object>
    )
}

export default Katalog;
