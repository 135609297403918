import React from "react";

// components
import { Container, Row, Col } from 'react-bootstrap';

// style
import '../Home/style.css';
import './style.css';


// images
import background1 from "../Home/images/background-1.jpg";

const AboutUs = () => {
    return (
        <div className="bg-home">
            <Container fluid>
                <Row>
                    <Col lg={6} className="p-0">
                        <div className="position-card-home">
                            <img src={background1} alt="Omcafe BIH" className="image-card-home" />
                        </div>
                    </Col>
                    <Col lg={6} className="p-0">
                        <div className="card-home">
                            <h4 className="subtitle-card-home">OMCAFÉ</h4>
                            <h2 className="title-card-home">Priča o posebnosti</h2>
                            <p className="text-card-home custom-margin-bottom">
                                Priča o šoljici vaše kafe počinje obilaskom gotovo cijelog svijeta. U potrazi za najboljim vrstama, stručnjaci iz naše kompanije posjećuju pojedinačne uzgajivače sirove kafe i grade dugoročne, kao i lične odnose sa njima. Odlični klimatski uslovi za uzgoj, kao i duga tradicija kultivisanja ove biljke, opredijelili su nas za visoke predele pojasa Ekvatora. Da bi se izabrale najbolje vrste sirove kafe, naši eksperti degustiraju svake godine preko 400 probnih prženja. Kafa koju uvozimo i način na koji je pržimo, neodvojivo su povezani jedno sa drugim. Da bi iz naših najkvalitetnijih zelenih zrna kafe izmamili cjelokupan spektar aroma, razvili smo poseban način mljevenja i prženja, a koji i dalje usavršavamo. Ovaj proces traje već od ranih devedesetih godina XX vijeka, kada je ujedno i osnovana kompanija „Bom Impeks“, propoznatljiva sa svojim brendom OMCAFÉ, prvim privatnim u to vrijeme u BiH.
                                </p>
                            <p className="text-card-home custom-margin-bottom">
                                Danas, OMCAFÉ je sastavni dio multinacinalne kompanije, čije poslovne aktivnosti obuhvataju proizvodnju, distribuciju, trgovinu i razvoj, sa sjedištima u Banja Luci, Novom Sadu, Beogradu, Ljubljani i Beču.
                                </p>
                            <p className="text-card-home custom-margin-bottom">
                                Stalnim usavršavanjem  tehnologije, u kontinuitetu održavamo visok i ujednačen nivo prženja i mljevenja, a posebnim načinom pakovanja, čuvamo sve one opojne arome koje vam isporučujemo u svakom OMCAFÉ proizvodu. Konačno, eksperti za kvalitet uz naše cijenjene kupce daju finalni pečat prepoznatljivom ukusu.
                                </p>
                            <p className="text-card-home custom-margin-bottom">
                                Pozivamo vas da probate i uživate u našoj i vašoj kafi, jer je svaka šoljica OMCAFÉ još jedan „Izgovor za razgovor”.
                                </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutUs;